import React, { useState, useCallback, useEffect } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { Tabs } from 'antd';

import { queryAppShelfInfoList, checkAuthenticated } from '../../../service';

import ComponentsPageInfo from '../ComponentsPageInfo';

import styles from './index.module.less';

const APPTabs = ({
  appTypeClassifyList = [],
  defaultActiveKey,
  initTotalCount,
  initAPPList,
}) => {
  const [paramData, setParamData] = useState({
    defaultActiveKey: defaultActiveKey || 'default',
    appClassify: '',
    pageNo: 1,
    pageSize: 15,
  });

  const [APPInfo, setAPPInfo] = useState({
    list: initAPPList,
    totalCount: initTotalCount,
  });

  const handleChangeTabs = useCallback((key = '') => {
    const appClassify = key === 'default' ? '' : key;

    setParamData({
      defaultActiveKey: key || 'default',
      appClassify, // 记录当前的 类型
      tabInfoObjArray: [], // 清空数据
      pageNo: 1,
      pageSize: 15,
    });
  }, []);

  // 初始化
  useEffect(() => {
    (async () => {
      const { pageNo, pageSize, appClassify } = paramData;
      const data = await checkAuthenticated();

      const authenticated = data && data.code === 0 ? true : false;

      const params = { authenticated, appClassify, pageNo, pageSize };
      const { success, items, totalCount } = await queryAppShelfInfoList(
        params
      );
      if (success) {
        unstable_batchedUpdates(() => {
          setAPPInfo({ totalCount, list: items });
        });
      }
    })();
  }, [paramData]);

  const handlePageChange = useCallback(
    (pageNo) => {
      setParamData({ ...paramData, pageNo });
    },
    [paramData]
  );

  return (
    <div className={styles['app-tabs']}>
      <Tabs
        defaultActiveKey={paramData.defaultActiveKey}
        onChange={handleChangeTabs}
        animated={false}
        tabPosition="left"
      >
        <Tabs.TabPane tab="推荐" key="default">
          <ComponentsPageInfo
            tabInfoObjArray={APPInfo.list}
            tabkey="default"
            handlePageChange={handlePageChange}
            // appClassify=""
            totalCount={APPInfo.totalCount}
          />
        </Tabs.TabPane>

        {appTypeClassifyList.map((item) => {
          return (
            <Tabs.TabPane tab={item.dictName} key={item.dictCode}>
              <ComponentsPageInfo
                tabInfoObjArray={APPInfo.list}
                handlePageChange={handlePageChange}
                totalCount={APPInfo.totalCount}
              />
            </Tabs.TabPane>
          );
        })}
      </Tabs>
    </div>
  );
};

export default APPTabs;
