import React, { useRef, useState, useCallback } from 'react';
import { Carousel } from 'antd';

import classnames from 'classnames';

import styles from './index.module.less';

const settings = {
  autoplay: true,
  infinite: true,
};

const Banner = ({ bannerList = [] }) => {
  const bannerListLength = bannerList.length;
  const [bannerIndex, setBannerIndex] = useState(1);
  const ref = useRef(null);

  const onChangeBanner = useCallback((index) => {
    setBannerIndex(index + 1);
  }, []);

  const handleBannerClick = useCallback((event, href) => {
    if (event.preventDefault) {
      event.preventDefault(); // 支持DOM标准的浏览器
    } else {
      window.event.returnValue = false; // IE
    }
    if (!href) return false;
    return window.open(href);
  }, []);

  return (
    <div className={styles.banner}>
      <Carousel ref={ref} afterChange={onChangeBanner} {...settings}>
        {bannerList.map((item, index) => {
          const sty = classnames(styles.imgGroupStyle, {
            [styles.clearCursor]: !item.linkUrl,
          });

          return (
            <a
              onClick={(event) => {
                handleBannerClick(event, item.linkUrl);
              }}
              className={sty}
              key={index}
            >
              {!!item.textShow && (
                <div className={styles.content}>
                  <span className={styles.sloganInfoStyle}>{item.name}</span>

                  <span
                    className={styles.appProfileStyle}
                    style={{ display: 'block', marginTop: '28px' }}
                    dangerouslySetInnerHTML={{
                      __html: (item.description || '').replace(/\n/g, '<br/>'),
                    }}
                  />
                </div>
              )}

              <div
                className={styles.imgBgStyle}
                style={{
                  backgroundImage: `url(${item.imageUrl})`,
                }}
              />
            </a>
          );
        })}
      </Carousel>
      {bannerList.length > 0 && (
        <div style={{ position: 'absolute', left: 325, top: 433 }}>
          <font className={styles.bannerTabStyle}>{`${bannerIndex}`}</font>
          <font className={styles.numberStyle}>{` / ${bannerListLength}`}</font>
        </div>
      )}
    </div>
  );
};

export default Banner;
