import React from 'react';
import Router, { withRouter } from 'next/router';
import { Row, Col, Rate, Pagination } from 'antd';
import Link from 'next/link';
import classnames from 'classnames';
import { map } from 'lodash';

import { locationPage } from '@utils/helper';

import styles from './index.module.less';

const ComponentsPageInfo = ({
  handlePageChange,
  tabInfoObjArray,
  totalCount = 0,
  pageSize = 15,
}) => {
  const searchAPPTag = (appTag, e) => {
    e.preventDefault();
    e.stopPropagation();
    Router.push({
      pathname: `/app-search`,
      query: { searchKey: appTag, throughTag: true },
    });
  };

  return (
    <div className={styles['components-page-info']}>
      <Row
        style={{
          position: 'relative',
        }}
        className={styles['row-style']}
      >
        {map(tabInfoObjArray, (tabInfoObj, index) => {
          const starArray = [];
          for (let i = 0; i < Number(tabInfoObj.evaluateNum); i += 1) {
            starArray.push(i);
          }
          const appTagArray =
            tabInfoObj.appTags == null ? [] : tabInfoObj.appTags.split(' ');

          // 如果标题过长导致换行 需要修改样式
          const breakLine =
            tabInfoObj.appTitle && tabInfoObj.appTitle.length > 13;
          return (
            <Col
              key={tabInfoObj.applicationID}
              span={8}
              className={styles.divStyle}
              onClick={() => {
                locationPage(
                  `/app-detail/${tabInfoObj.applicationID}`,
                  'blank'
                );
              }}
            >
              <img
                className={styles.sloganPicStyle}
                src={`${tabInfoObj.appLogo}`}
                alt={`${index}Pic`}
                style={{ width: '68px', height: '68px' }}
              />
              <div
                style={{
                  flex: 1,
                }}
                className={styles.RightBorder}
              >
                <font className={styles.sloganTitleStyle}>
                  {tabInfoObj.appTitle}
                </font>
                <Rate
                  className={styles.starStyle}
                  style={{ fontSize: 14 }}
                  disabled
                  defaultValue={+tabInfoObj.averageScore}
                  count={5}
                  allowHalf
                />
                <font className={styles.evaluateNumStyle}>
                  {tabInfoObj.actualAvgScore}
                </font>
                <div
                  className={classnames(styles.sloganAbstractDiv, {
                    [styles.sloganAbstractDiv44]: breakLine,
                  })}
                >
                  <font
                    style={{ WebkitBoxOrient: 'vertical', color: '#999' }}
                    className={classnames(styles.sloganAbstract, {
                      [styles.clamp2]: breakLine,
                    })}
                  >
                    {tabInfoObj.appProfile}
                  </font>
                </div>
                <div style={{ height: '2px' }}>&nbsp;</div>
                {appTagArray.length > 0 && (
                  <div>
                    {appTagArray.map((item, i) => (
                      <span key={i} onClick={(e) => searchAPPTag(item, e)}>
                        <font className={styles['tags-abstract']}>{item}</font>
                        &nbsp;&nbsp;
                      </span>
                    ))}
                  </div>
                )}
              </div>
            </Col>
          );
        })}
      </Row>
      {totalCount !== 0 ? (
        <Pagination
          style={{ marginTop: '40px' }}
          onChange={(pageNo) => handlePageChange(pageNo)}
          defaultCurrent={1}
          pageSize={pageSize}
          total={totalCount}
          showSizeChanger={false}
        />
      ) : null}
    </div>
  );
};

export default withRouter(ComponentsPageInfo);
