import React from 'react';
import { observer, inject } from 'mobx-react';
import Head from 'next/head';

import BasicLayout from '../layouts/BasicLayout';
import Banner from '../pageComponents/Index/Banner';
import SearchInput from '../pageComponents/Index/SearchInput';
import APPTabs from '../pageComponents/Index/APPTabs';

import {
  queryDictListByTypeCode,
  queryBannerInfoList,
  queryAppShelfInfoList,
  queryDevelopers,
} from '../service';

import styles from './index.module.less';

@inject('appGlobalModel')
@inject('modelIndex')
@BasicLayout()
@observer
class IndexPage extends React.Component {
  constructor(props) {
    super(props);
    this.globalStore = props.appGlobalModel;
    this.store = props.modelIndex;
  }

  render() {
    const { appTypeClassifyList } = this.globalStore;
    const { bannerList, totalCount, appList } = this.store;
    console.log('434234', appTypeClassifyList, appList);
    return (
      <div className={styles['page-index']}>
        <Head>
          {/* <title>应用商店-工业APP-工业软件-让企业轻松上云</title> */}
          <meta
            name="keywords"
            content="应用商店，应用商店，SaaS,工业APP,工业软件，上云，应用市场"
          ></meta>
          <meta
            name="description"
            content="应用商店是向企业用户提供的应用分发渠道，包括工业APP,工业软件，解决方案类产品，为繁荣工业互联网平台的应用生态、促进工业提质增效和转型升级发挥重要支撑作用。"
          ></meta>
        </Head>
        <Banner bannerList={bannerList} />
        <div className={styles.center}>
          <SearchInput queryDevelopers={queryDevelopers} />
          <APPTabs
            appTypeClassifyList={appTypeClassifyList}
            initTotalCount={totalCount}
            initAPPList={appList}
          />
        </div>
      </div>
    );
  }
}

export async function getStaticProps({ params }) {
  const [
    {
      content: { dictList },
    },
    bannerRes,
    { items, totalCount },
  ] = await Promise.all([
    queryDictListByTypeCode(),
    queryBannerInfoList({
      fieldCode: 'store',
      pageCode: 'store_boss',
    }),
    queryAppShelfInfoList({
      authenticated: false,
      appClassify: '',
      pageNo: 1,
      pageSize: 15,
    }),
  ]);

  if (process.env.REACT_APP_ENV == 'cnmc') {
    let index = dictList.findIndex(
      (item) => item.dictCode === 'mechanism_model'
    );
    dictList.splice(index, 1);
  }

  // 通过 props 参数向页面传递数据
  return {
    props: {
      staticProps: {
        appGlobalModel: {
          appTypeClassifyList: dictList,
        },
        modelIndex: {
          bannerList: (bannerRes || {}).data || [],
          totalCount,
          appList: items || [],
        },
      },
    },
    revalidate: 60,
  };
}

export default IndexPage;
