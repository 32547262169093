import React, { useState, useCallback } from 'react';
import { withRouter } from 'next/router';
import { Input, Select, Alert, AutoComplete } from 'antd';

import { isNil } from 'lodash';

import { locationPage } from '@utils/helper';

import styles from './index.module.less';

const { Option } = Select;
const { Option: Auto } = AutoComplete;
let lastFetchId = 0;

const SearchInput = ({ queryDevelopers }) => {
  const [searchText, setSearchText] = useState('');
  const [searchState, setSearchState] = useState('app');
  const [developerList, setDeveloperList] = useState([]);
  const [selectedDeveloper, setSelectedDeveloper] = useState();
  const [fetching, setFetching] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const handleChange = useCallback((event) => {
    const { value } = event.target;
    setSearchText(value);
  }, []);

  const onHandlePressEnter = useCallback((event) => {
    const searchText = event.target.value;
    if (!isNil(searchText) && searchText.length) {
      locationPage(
        `/app-search?searchKey=${encodeURIComponent(searchText)}`,
        'blank'
      );
    }
  }, []);

  const onClickSearch = useCallback(() => {
    if (!isNil(searchText) && searchText.length > 0) {
      locationPage(
        `/app-search?searchKey=${encodeURIComponent(searchText)}`,
        'blank'
      );
    }
  }, [searchText]);

  const handelSearchTypeChange = (value) => {
    setSearchState(value);
  };

  const fetchUser = async (value = '') => {
    lastFetchId += 1;
    const fetchId = lastFetchId;
    setDeveloperList([]);
    setFetching(false);
    queryDevelopers({
      searchName: value.trim(),
    }).then((res) => {
      const { data } = res;
      if (fetchId !== lastFetchId) {
        return;
      }
      setDeveloperList(
        data.map((user) => (
          <Auto key={user.accountId} value={user.userName}>
            {user.userName}
          </Auto>
        ))
      );
      if (data.length) {
        setShowAlert(false);
      } else {
        setShowAlert(true);
      }
      setFetching(false);
    });
  };

  const handleDevSearchChange = (value = '') => {
    setSelectedDeveloper(value);
    setDeveloperList([]);
    setFetching(false);
  };

  const handleDevSearchSelect = (value, option) => {
    goToDevloper(option.key);
  };

  const goToDevloper = (value) => {
    locationPage(`/app-developer/${value}`, 'blank');
  };

  return (
    <div className={styles['search-input']}>
      <Select
        defaultValue="app"
        style={{ width: 130 }}
        onChange={handelSearchTypeChange}
      >
        <Option value="app">搜索APP</Option>
        <Option value="developer">搜索开发者</Option>
      </Select>
      {searchState === 'app' && (
        <Input
          placeholder="搜索工业APP"
          onPressEnter={onHandlePressEnter}
          onChange={handleChange}
          autoFocus
        />
      )}
      {searchState === 'developer' && (
        <div className={styles['dev-input']}>
          <AutoComplete
            style={{ width: '100%' }}
            dataSource={developerList}
            onSelect={handleDevSearchSelect}
            onSearch={fetchUser}
            onChange={handleDevSearchChange}
            placeholder="搜索开发者"
          />
          {showAlert ? (
            <Alert
              message="没有匹配的开发者，请重新输入或选择"
              type="warning"
              showIcon
              closable
            />
          ) : null}
        </div>
      )}
      <img
        className={styles.searchLogoStyle}
        src="/images/search.svg"
        alt="inconSearch"
        onClick={async () => {
          if (searchState === 'app') {
            onClickSearch();
          } else {
            let key = '';
            await developerList.map((item) => {
              if (item.props.value === selectedDeveloper) {
                key = item.key;
              }
              return item.props.value;
            });
            if (key) {
              goToDevloper(key);
            } else {
              setShowAlert(true);
            }
          }
        }}
      />
    </div>
  );
};

export default withRouter(SearchInput);
